<template>
    <div>
        <div class="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden">
            <img-comparison-slider ref="slider" keyboard="disabled" tabindex="-1">
                <img
                    slot="first"
                    v-lazy-load
                    :data-src="before"
                    alt="before"
                    class="w-full h-full object-cover object-center"
                />
                <img
                    slot="second"
                    v-lazy-load
                    :data-src="after"
                    alt="after"
                    class="w-full h-full object-cover object-center"
                />
                <div
                    slot="handle"
                    class="custom-handle w-16 h-16 bg-white rounded-full flex items-center justify-center"
                >
                    <i class="ni ni-angle-arrow-left mr-2 text-lg text-green-400"></i>
                    <i class="ni ni-angle-arrow-right ml-2 text-lg text-green-400"></i>
                </div>
            </img-comparison-slider>
        </div>
        <h2 class="subtitle mt-4 text-center">{{ label }}</h2>
    </div>
</template>

<script>
export default {
    name: 'ComparisonCard',
    props: {
        before: {
            type: String,
            required: true,
        },
        after: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    mounted() {
        const stylesheet = this.$refs.slider.shadowRoot.styleSheets[0];
        stylesheet.addRule(':host .divider', 'cursor: grab');
        stylesheet.addRule(':host .divider:active', 'cursor: grabbing');
    },
};
</script>

<style>
img-comparison-slider {
    --divider-width: 4px;
}
</style>
