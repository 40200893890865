<template>
    <div v-if="gridItems.length" id="employee-carousel" :class="{ swiper: $device.isMobile }">
        <div
            :class="$device.isMobile ? 'swiper-wrapper' : 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4'"
            style="grid-auto-flow: dense"
        >
            <div
                v-for="(employee, i) in employees"
                :key="`employee-grid-item-${employee.id}`"
                class="relative rounded-2xl overflow-hidden sm:col-span-1"
                :class="{
                    'swiper-slide h-44 xs:h-64 md:h-80': $device.isMobileOrTablet,
                    'min-h-[350px]': $device.isDesktop,
                }"
            >
                {{/* do not simplify the classes above with dynamically generated ones, tailwind needs them */}}
                <NuxtLink
                    :to="localePath({ name: 'about-dentists-dentist', params: { dentist: employee.slug } })"
                    rel="noopener noreferrer"
                    class="absolute h-full w-full cursor-pointer"
                >
                    <img
                        class="h-full w-full object-cover"
                        v-lazy-load
                        :data-src="employee.image"
                        :alt="employee.name"
                    />
                    <div
                        class="
                            employee-details-wrapper
                            bg-dark-700 bg-opacity-90
                            text-white
                            absolute
                            left-0
                            top-0
                            h-full
                            w-full
                            p-6
                            flex flex-col
                            justify-end
                            opacity-0
                            transition-all
                        "
                    >
                        <div class="employee-details translate-y-4 transition-transform max-w-sm">
                            <span class="font-bold mb-2 leading-7 font-HKGrotesk-Bold text-xl">
                                {{ employee.name }}
                            </span>
                            <div v-if="employee.tags.length">
                                <div class="font-HKGrotesk-Bold">{{ $t('dentist.specializations') }}:</div>
                                <div v-for="(tag, j) in employee.tags" :key="`employee-grid-item-tag-${i}-${j}`">
                                    {{ tag.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </NuxtLink>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SectionEmployeeGrid',
    props: {
        employees: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            employeeCarousel: null,
            gridItems: [],
        };
    },
    mounted() {
        const layouts = {
            1: [[2, 1]],
            2: [
                [2, 1],
                [2, 1],
            ],
            3: [
                [1, 1],
                [1, 1],
                [2, 1],
            ],
            4: {
                0: [
                    [2, 2],
                    [1, 2],
                    [1, 1],
                    [1, 1],
                ],
                1: [
                    [1, 2],
                    [2, 2],
                    [1, 1],
                    [1, 1],
                ],
                2: [
                    [1, 2],
                    [1, 1],
                    [2, 2],
                    [1, 1],
                ],
                3: [
                    [2, 2],
                    [1, 1],
                    [1, 2],
                    [1, 1],
                ],
                4: [
                    [1, 1],
                    [2, 2],
                    [1, 2],
                    [1, 1],
                ],
            },
            5: [
                [2, 1],
                [1, 1],
                [1, 2],
                [1, 1],
                [2, 1],
            ],
        };

        const res = [];

        const process = () => {
            const r = this.employees.length - res.length;

            if (r === 0) {
                return;
            }

            if (r < 4 || r === 5) {
                res.push(...layouts[r]);
            } else {
                res.push(...layouts[4][Math.floor(r / 4) % 5]);
                process();
            }
        };

        process();

        this.gridItems = res;

        if (this.$device.isMobile) {
            this.$nextTick(() => {
                this.initCarousel();
            });
        }
    },
    methods: {
        initCarousel() {
            this.employeeCarousel = new this.$swiper('#employee-carousel', {
                slidesPerView: 2.5,
                spaceBetween: 12,
                resistance: true,
                resistanceRatio: 0,
                allowTouchMove: this.$device.isMobileOrTablet,
                speed: 1000,
                loop: true,
            });
        },
    },
};
</script>

<style scoped>
@media (hover: hover) {
    .employee-details-wrapper:hover {
        opacity: 1;
        .employee-details {
            transform: translateY(0);
        }
    }
}
</style>
