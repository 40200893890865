<template>
    <div
        class="bg-green-200 rounded-full relative flex items-center justify-center"
        :class="{
            'w-20 h-20': size === 'sm',
            'w-40 h-40 min-w-40 min-h-40 lg:h-52 lg:w-52 lg:min-w-52 lg:min-h-52 mx-auto': size === 'lg',
        }"
        style="background-color: rgba(190, 250, 225, 0.3)"
    >
        <div
            class="absolute w-[77%] h-[77%] bg-white rounded-full flex items-center justify-center"
            style="box-shadow: 0 10px 25px rgba(17, 62, 81, 0.15)"
        >
            <div
                class="w-3/4 h-3/4 bg-contain bg-center bg-no-repeat"
                :class="{ 'w-2/4 h-2/4 md:w-3/4 md:h-3/4': hasSmallIconOnMobile }"
                v-lazy-background :lazy-background="icon"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServiceIcon',
    props: {
        icon: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            required: false,
            default: 'lg',
        },
        hasSmallIconOnMobile: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>
