<template>
    <div class="flex flex-col justify-center">
        <ServiceIcon :title="title" :icon="icon" has-small-icon-on-mobile />
        <div class="text-dark-700 text-center mt-7">
            <h3 class="text-2xl lg:text-3xl font-HKGrotesk-Bold mb-4 lg:mb-5">{{ title }}</h3>
            <p v-if="description.length" class="text-base leading-6">{{ description }}</p>
        </div>
    </div>
</template>

<script>
import ServiceIcon from '@/components/Icons/ServiceIcon/ServiceIcon';

export default {
    name: 'FeatureCard',
    components: {
        ServiceIcon,
    },
    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
    },
};
</script>
