<template>
    <section class="bg-primary-100 white-offset relative">
        <div class="container">
            <div class="grid grid-cols-12 pt-5 lg:pt-15 pb-7 row-auto">
                <div class="col-span-12 lg:col-span-5 relative">
                    <div class="lg:absolute lg:w-[140%] 3xl:w-[100%] right-0">
                        <div class="aspect-w-3 aspect-h-2 lg:aspect-h-3">
                            <img
                                v-lazy-load
                                class="rounded-2xl lg:ml-auto object-cover lg:h-[calc(100%-2.5rem)]"
                                :data-src="require('~/assets/images/generic/laser_dentistry.webp')"
                                :alt="$t('laser_dentistry.why_is_it_good')"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-span-12 lg:col-span-7 relative">
                    <div class="bg-white pt-8 pb-10 px-4 lg:pl-8 lg:pr-16 rounded-2xl mb-4 lg:mb-7">
                        <h3 class="subtitle mb-6"><T t="laser_dentistry.why_is_it_good" /></h3>
                        <ul>
                            <li><T t="laser_dentistry.advantage_1" /></li>
                            <li><T t="laser_dentistry.advantage_2" /></li>
                            <li><T t="laser_dentistry.advantage_3" /></li>
                            <li><T t="laser_dentistry.advantage_4" /></li>
                            <li><T t="laser_dentistry.advantage_5" /></li>
                            <li><T t="laser_dentistry.advantage_6" /></li>
                            <li><T t="laser_dentistry.advantage_7" /></li>
                            <li><T t="laser_dentistry.advantage_8" /></li>
                        </ul>
                    </div>
                    <div class="text-white px-4 lg:px-6 pt-5 pb-5 bg-dark-700 rounded-2xl">
                        <h3 class="subtitle mb-4">
                            <T t="laser_dentistry.we_are_pioneers" />
                        </h3>
                        <p class="text-sm lg:text-base">
                            <T t="laser_dentistry.we_have_the_best_team" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'InfoBlock',
};
</script>

<style scoped>
.white-offset {
    &::before {
        content: '';
        @apply absolute -bottom-1 block h-24 bg-white left-0 w-full;
    }
}

ul li {
    @apply relative pl-5 md:pl-7 text-sm lg:text-base text-dark-600 mb-2 lg:mb-5;

    &::before {
        content: '';
        @apply block absolute top-2 left-0 w-2 h-2 rounded-full bg-green-500;
    }

    &:last-child {
        @apply mb-0;
    }
}
</style>
