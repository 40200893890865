<template>
    <div>
        <h2 v-if="title" class="title text-center mb-12 lg:mb-20 mt-16 lg:mt-28">{{ title }}</h2>

        <div id="article-carousel" :class="{ 'swiper px-4 sm:px-0': $device.isMobileOrTablet }">
            <div :class="$device.isMobileOrTablet ? 'swiper-wrapper' : 'grid grid-cols-12'">
                <ArticleCard
                    v-for="article in articles"
                    :key="`article-${article.id}`"
                    :authors="article.authors || []"
                    :title="article.title"
                    :description="article.lead"
                    :img-src="article.cover_image_cropped"
                    :release-date="article.published_at"
                    :slug="article.slug"
                    :tags="article.tags"
                    class="col-span-12 sm:col-span-6 lg:col-span-4"
                    :class="{ 'swiper-slide': $device.isMobileOrTablet }"
                    auto-height
                />
            </div>
        </div>

        <div class="text-center mt-10 md:mt-15" :class="{ 'px-5': $device.isMobileOrTablet }">
            <ButtonLink
                :to="localePath('blog')"
                :label="$t('generic.more_articles')"
                class="p-button-md/lg xs-w-full"
            />
        </div>
    </div>
</template>

<script>
import ArticleCard from '@/components/Cards/ArticleCard/ArticleCard';
export default {
    name: 'ArticleGrid',
    components: {
        ArticleCard,
    },
    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        articles: {
            type: Array,
            required: true,
        },
    },
    mounted() {
        this.articleCarousel = new this.$swiper('#article-carousel', {
            slidesPerView: 1.2,
            spaceBetween: 16,
            allowTouchMove: this.$device.isMobileOrTablet,
            // loop: this.articles.length >= 2,
            breakpoints: {
                480: {
                    slidesPerView: 1.6,
                },
                990: {
                    slidesPerView: 3,
                    loop: this.articles.length >= 3,
                    spaceBetween: 28,
                },
            },
        });
    },
    beforeDestroy() {
        this.articleCarousel.destroy();
    },
};
</script>

<style scoped></style>
