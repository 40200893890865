<template>
    <article class="transition rounded-2xl border border-light-350 overflow-hidden" :class="{ 'h-full': !autoHeight }">
        <NuxtLink
            :to="localePath({ name: 'blog-article', params: { article: slug } })"
            rel="noopener noreferrer"
            class="h-full flex flex-col"
        >
            <div class="relative w-full aspect-w-5 aspect-h-3">
                <img v-lazy-load class="object-cover w-full lg:h-full" :data-src="imgSrc" :alt="title" />
            </div>
            <div class="pt-4 px-6 pb-5 flex-grow flex flex-col justify-between text-dark-700">
                <div>
                    <div v-if="tags" class="flex gap-x-4">
                        <span
                            v-for="tag in tags.slice(0, 2)"
                            :key="`tag-${tag.id}`"
                            class="text-dark-500 text-xs whitespace-nowrap"
                        >
                            #{{ tag.name }}
                        </span>
                    </div>
                    <h3 class="mt-2 font-HKGrotesk-SemiBold text-lg lg:font-HKGrotesk-Bold lg:text-base">
                        {{ title }}
                    </h3>
                    <div class="body-2 mt-4 mb-4 line-clamp-3">
                        {{ description }}
                    </div>
                </div>
                <span class="text-xs text-dark-500">
                    {{ $dayjs(releaseDate * 1000).format('YYYY. MMMM D.') }}
                    {{ authors.length ? ' - ' + formattedAuthors : '' }}
                </span>
            </div>
        </NuxtLink>
    </article>
</template>

<script>
export default {
    name: 'Card',
    props: {
        slug: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        imgSrc: {
            type: String,
            required: true,
        },
        releaseDate: {
            type: Number,
            required: true,
        },
        authors: {
            type: Array,
            required: false,
            default: () => [],
        },
        tags: {
            type: Array,
            required: false,
            default: () => [],
        },
        autoHeight: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        formattedAuthors() {
            return this.authors.map((x) => x.name).join(', ');
        },
    },
};
</script>

<style scoped>
article {
    &:hover {
        box-shadow: 0 4px 30px rgba(28, 130, 150, 0.2);
    }
}
</style>
