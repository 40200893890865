<template>
    <div class="py-10 bg-warning bg-opacity-20">
        <div class="container">
            <div class="grid grid-cols-12">
                <div class="col-span-12 xl:col-span-8 xl:col-start-3 text-dark-700">
                    <div class="grid grid gap-0 md:auto-cols-max">
                        <div class="row-span-1 md:row-span-2 pl-5 pr-12 flex items-center">
                            <img class="w-15 h-auto" :src="icon" :alt="title" />
                        </div>
                        <div class="flex flex-col col-start-2">
                            <span class="font-HKGrotesk-Bold mb-2">{{ title }}</span>
                            <span class="md:mb-7">{{ subtitle }}</span>
                        </div>
                        <div v-if="slug" class="col-span-2 md:col-start-2 md:col-span-auto mt-7 md:mt-0">
                            <ButtonLink
                                class="p-button-md/lg w-full xs:w-max"
                                :label="$t('generic.more_information')"
                                :to="
                                    localePath({
                                        name: 'about-notifications-notification',
                                        params: { notification: slug },
                                    })
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotificationBlock',
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        slug: {
            type: String,
            required: false,
            default: null,
        },
    },
};
</script>

<style scoped>
.box-shadow {
    box-shadow: 0 4px 20px rgba(28, 130, 150, 0.1);
}
input {
    &::placeholder {
        @apply text-dark-500;
    }
}
</style>
