<template>
    <div
        id="about-block"
        class="
            grid grid-cols-12
            lg:gap-y-0
            mt-20
            lg:mt-24
            pt-5
            pb-10
            lg:py-15
            px-3
            md:px-6
            lg:px-0
            rounded-lg
            bg-light-150
            box-shadow
        "
    >
        <div class="col-span-12 lg:col-span-6 lg:col-start-2 text-dark-700 lg:pl-9">
            <h3 class="title-2 text-center lg:text-left mb-7 lg:mb-6">
                <T t="homepage.our_mission.title" />
            </h3>
            <ul class="our-mission-list">
                <li><T t="homepage.our_mission.mission_1" /></li>
                <li><T t="homepage.our_mission.mission_2" /></li>
                <li><T t="homepage.our_mission.mission_3" /></li>
                <li><T t="homepage.our_mission.mission_4" /></li>
            </ul>
        </div>
        <div class="col-span-12 lg:col-span-4">
            <div class="grid grid-cols-2 lg:gap-y-15">
                <div v-for="(item, i) in stats" :key="`stat-${i}`">
                    <Transition name="fade">
                        <number
                            v-if="play"
                            :from="0"
                            :to="item.value"
                            :duration="2"
                            easing="Power4.easeOut"
                            class="header-1 text-green-500 whitespace-nowrap"
                        />
                    </Transition>
                    <br />
                    <span class="body-2 text-dark-700">{{ item.title }}</span>
                </div>
            </div>
        </div>
        <div class="col-span-12 lg:col-span-6 lg:col-start-2 mt-5 lg:mt-0 lg:pl-9">
            <ButtonLink
                :to="localePath('about-introduction')"
                :label="$t('homepage.our_mission.button_label')"
                class="xs-w-full"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Block',
    props: {
        stats: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            play: false,
            visible: false,
            intersectionOptions: {
                root: null,
                rootMargin: '0px 0px 0px 0px',
                threshold: 1,
            },
        };
    },
    mounted() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > 0) {
                    this.play = true;
                }
            });
        });
        const block = document.querySelector('#about-block');
        observer.observe(block);
    },
};
</script>

<style scoped>
.box-shadow {
    @media (min-width: 768px) {
        box-shadow: 0 4px 30px rgba(28, 130, 150, 0.2);
    }
}

.our-mission-list li {
    @apply pl-8 relative mb-3;
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: 0;
        width: 14px;
        height: 14px;
        background-image: url('~/assets/images/icons/icon-checkmark--green.svg');
        @apply mr-4 bg-contain bg-center bg-no-repeat;
    }
}
</style>
