<template>
    <NuxtLink
        :to="path"
        class="
            flex
            h-full
            sm:flex-row
            border border-light-300
            rounded-lg
            md:rounded-2xl
            p-2
            pr-3
            sm:py-6 sm:px-5 sm:items-start
            transition
            box-shadow--hover
            hover:border-green-400 hover:-translate-y-1
        "
    >
        <div
            class="
                h-16
                w-16
                min-w-16 min-h-16
                lg:h-24 lg:w-24 lg:min-w-24 lg:min-h-24
                bg-cover bg-center
                mb-2
                sm:mb-0
                flex-none
            "
            v-lazy-background :lazy-background="icon"
        ></div>
        <div class="ml-4">
            <h3 class="font-HKGrotesk-Bold lg:font-HKGrotesk-SemiBold text-lg lg:text-2xl mb-1 sm:mb-4 text-left">
                {{ title }}
            </h3>
            <div class="text-sm" v-html="description"></div>
        </div>
    </NuxtLink>
</template>

<script>
export default {
    name: 'ServiceCard',
    props: {
        path: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.box-shadow--hover {
    &:hover {
        box-shadow: 0 4px 30px rgb(28 130 150 / 10%);
    }
}
</style>
